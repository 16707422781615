export const login = async (email: string, password: string) => {
  const response = await fetch(
    "https://smartdc.linceonline.com.br/back/api/auth/login.php",
    {
      method: "POST",
      body: JSON.stringify({ email, password }),
    }
  );
  return response.json();
};

export const verifyToken = async (token: string) => {
  const response = await fetch(
    "https://smartdc.linceonline.com.br/back/api/auth/auth.php",
    {
      method: "POST",
      body: JSON.stringify({ token }),
    }
  );
  return response.json();
};

export const register = async (
  email: string,
  password: string,
  name: string
) => {
  const response = await fetch(
    "https://smartdc.linceonline.com.br/back/api/users/index.php",
    {
      method: "POST",
      body: JSON.stringify({ email, password, name, fn: "create" }),
    }
  );
  return response.json();
};
