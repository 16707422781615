import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  resources: {
    en: {
      translation: {
        welcome: "Welcome!",
        login: "Login",
        email: "Email",
        password: "Password",
        enter: "Enter",
        register: "Register",
        createAccount: "Create an account",
        fillFields: "Fill in the fields below to register",
        name: "Name",
        pieceInfo: "Part Information",
        d1Title: "D1 - Spherical radius",
        d2Title: "D2 - Spherical center",
        d3Title: "D3 - Length face B",
        d4Title: "D4 - Length face opposite B",
        piece: "Part",
        deviation: "Deviation",
        outOfTolerance: "Out of Tolerance",
        model: "Model",
        size: "Size",
        serialNumber: "Serial Number",
        ambientTemperature: "Ambient Temperature",
        climatizedPiece: "Climatized Part",
        donthaveAccount: "Do you not have an account? Create one now",
        enterEmailAndPassword: "Enter your email and password to log in",
        enterDataRegister: "Fill in the fields below to register",
        ifAlreadyHaveAccount:
          "If you are already registered, access your account now",
        yes: "Yes",
        no: "No",
        back: "Back",
        next: "Next",
        save: "Finish",
        adjustsOnProgram: "Adjusts for the CNC program",
        backToDashboard: "Back to Dashboard",
        logout: "Logout",
      },
    },
    pt: {
      translation: {
        welcome: "Bem-vindo!",
        login: "Entrar",
        email: "Email",
        password: "Senha",
        enter: "Entrar",
        register: "Registrar",
        createAccount: "Criar uma conta",
        fillFields: "Preencha os campos abaixo para se registrar",
        name: "Nome",
        pieceInfo: "Informações da Peça",
        d1Title: "D1 - Raio do esférico",
        d2Title: "D2 - Altura da divisória",
        d3Title: "D3 - Comprimento face B",
        d4Title: "D4 - Comprimento face oposta a B",
        piece: "Peça",
        deviation: "Desvio",
        outOfTolerance: "Fora de Tolerância",
        model: "Modelo",
        size: "Tamanho",
        serialNumber: "Número de série",
        ambientTemperature: "Temperatura ambiente",
        climatizedPiece: "Peça climatizada",
        donthaveAccount: "Não possui uma conta? Crie uma agora",
        enterEmailAndPassword: "Digite seu email e senha para fazer login",
        enterDataRegister: "Preencha os campos abaixo para se registrar",
        ifAlreadyHaveAccount: "Se você já é cadastrado, acesse sua conta agora",
        yes: "Sim",
        no: "Não",
        back: "Anterior",
        next: "Próximo",
        save: "Finalizar",
        adjustsOnProgram: "Ajustes Para o Programa CNC",
        backToDashboard: "Voltar para o Dashboard",
        logout: "Sair",
      },
    },
  },
  detection: {
    order: ["navigator", "localStorage", "cookie"],
    caches: ["localStorage", "cookie"],
  },
  interpolation: {
    escapeValue: false, // React already escapes by default
  },
});

export default i18n;
