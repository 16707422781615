import React, { useEffect, useState } from "react";

import { Navigate, Outlet } from "react-router-dom";

import ReactLoading from "react-loading";

import { verifyToken } from "api/auth";
import { Navbar } from "components/Navbar";

const PrivateRoute: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    (async () => {
      if (!!localStorage.getItem("user:smart-dc")) {
        const user = JSON.parse(
          localStorage.getItem("user:smart-dc") as string
        );
        if (user.token) {
          await getIsAuthenticated(user.token);
        }
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    })();
  }, []);

  const getIsAuthenticated = async (token: string) => {
    try {
      const auth = await verifyToken(token);
      const userValid = Object.keys(auth).length > 0;
      setIsAuthenticated(userValid);
    } catch (e) {
      console.error(e);
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className="flex w-full items-center justify-center h-[100vh]">
        <ReactLoading type="spin" color="#172554" height={50} width={50} />
      </div>
    );
  return isAuthenticated ? (
    <>
      <Navbar />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
