import React, { useState } from "react";
import {
  dataItems,
  imagesSteps,
  stepsTitles,
  optionsTypeOption,
  OptionsTypeOptionKeys,
} from "./utils/dataItems";
import { register } from "api/piece";
import ReactLoading from "react-loading";
import { PiecesData } from "types/pieces";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [itemsRenderData, setItemsRenderData] = useState(dataItems);

  const handlePassStep = () => setStep((state) => state + 1);
  const handleBackStep = () => setStep((state) => state - 1);

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (step < itemsRenderData.length - 1) {
      handlePassStep();
      return;
    }

    setLoading(true);
    const data = itemsRenderData.map((items) => {
      const data = items.reduce((acc, item) => {
        acc[item.name] = item.value;
        return acc;
      }, {} as { [key: string]: string });
      return data;
    });
    const dataSend = Object.assign({}, ...data) as PiecesData;

    try {
      const piece = await register({
        peca_climatizada: dataSend.peca_climatizada.toString() === "sim",
        numero_de_serie: String(dataSend.numero_de_serie),
        modelo: Number(dataSend.modelo),
        tamanho: Number(dataSend.tamanho),
        temperatura_ambiente: Number(dataSend.temperatura_ambiente),
        desvio_raio_do_esferico: Number(dataSend.desvio_raio_do_esferico),
        fora_de_tolerancia_raio_do_esferico: Number(
          dataSend.fora_de_tolerancia_raio_do_esferico
        ),
        desvio_altura_da_divisoria: Number(dataSend.desvio_altura_da_divisoria),
        fora_de_tolerancia_altura_da_divisoria: Number(
          dataSend.fora_de_tolerancia_altura_da_divisoria
        ),
        desvio_comprimento_face_b: Number(dataSend.desvio_comprimento_face_b),
        fora_de_tolerancia_comprimento_face_b: Number(
          dataSend.fora_de_tolerancia_comprimento_face_b
        ),
        desvio_comprimento_face_oposta: Number(
          dataSend.desvio_comprimento_face_oposta
        ),
        fora_de_tolerancia_comprimento_face_oposta: Number(
          dataSend.fora_de_tolerancia_comprimento_face_oposta
        ),
        fn: "create",
      });

      if (piece) {
        setItemsRenderData(dataItems);
        setStep(0);
        navigate("/results", { state: { piece } });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setItemsRenderData((state) =>
      state.map((items, index) =>
        index === step
          ? items.map((item) =>
              item.name === name ? { ...item, value } : item
            )
          : items
      )
    );
  };

  return (
    <div className="h-full flex items-center justify-center">
      <div className="*:p-8 w-full max-w-7xl">
        <h1 className="text-3xl font-bold text-blue-900 text-center">
          {t(stepsTitles[step])}
        </h1>
        <div className="flex flex-row justify-between gap-12">
          <div className="w-2/3 flex items-center justify-center">
            <img src={imagesSteps[step]} alt={t("piece")} />
          </div>
          <form className="w-1/3 flex flex-col my-auto" onSubmit={onFormSubmit}>
            <div className="grid grid-cols-1 gap-4">
              {itemsRenderData[step]?.map((item) => (
                <div key={item.name}>
                  <label htmlFor={item.name} className="block text-gray-700">
                    {t(item.title)} {item.unit && `(${item.unit})`}
                  </label>
                  {item.type === "text" || item.type === "number" ? (
                    <input
                      type={item.type}
                      id={item.name}
                      required
                      className="w-full border border-gray-300 p-2 rounded"
                      name={item.name}
                      value={item.value}
                      onChange={onChangeValue}
                    />
                  ) : item.type === "select" ? (
                    <select
                      id={item.name}
                      required
                      className="w-full border border-gray-300 p-2 rounded bg-white"
                      name={item.name}
                      value={item.value}
                      onChange={onChangeValue}
                    >
                      {optionsTypeOption[item.name as OptionsTypeOptionKeys] &&
                        optionsTypeOption[
                          item.name as OptionsTypeOptionKeys
                        ].map(({ label, value }) => (
                          <option key={label + value} value={value}>
                            {label}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <div className="flex items-center">
                      <input
                        type="radio"
                        id="sim"
                        name={item.name}
                        value="sim"
                        className="mr-2"
                        required
                        checked={item.value === "sim"}
                        onChange={onChangeValue}
                      />
                      <label htmlFor="sim" className="mr-4 text-gray-700">
                        {t("yes")}
                      </label>
                      <input
                        type="radio"
                        id="nao"
                        name={item.name}
                        value="nao"
                        className="mr-2"
                        required
                        checked={item.value === "nao"}
                        onChange={onChangeValue}
                      />
                      <label htmlFor="nao" className="text-gray-700">
                        {t("no")}
                      </label>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex flex-row justify-between items-center">
              {step > 0 && (
                <button
                  type="button"
                  onClick={handleBackStep}
                  className="bg-blue-900 mt-8 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                  {t("back")}
                </button>
              )}
              {step < itemsRenderData.length - 1 ? (
                <button
                  type="submit"
                  className="bg-blue-900 mt-8 ml-auto text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                  {t("next")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="bg-blue-900 mt-8 ml-auto text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                  {loading ? (
                    <ReactLoading
                      className="mx-auto"
                      type="spin"
                      color="#172554"
                      height={20}
                      width={20}
                    />
                  ) : (
                    t("save")
                  )}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
