import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import resultsPiece from "assets/results-piece.svg";
import {
  getDecisionTreeResultD1,
  getDecisionTreeResultD2,
  getDecisionTreeResultD3,
  getDecisionTreeResultD4,
} from "./utils/functions";
import { PiecesData } from "types/pieces";
import { useTranslation } from "react-i18next";

export const ResultsPage: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state.piece) {
      navigate("/dashboard");
    }
  }, [location, navigate]);

  const handleBackToDashboard = () => {
    navigate("/dashboard");
  };

  const {
    desvio_raio_do_esferico,
    fora_de_tolerancia_altura_da_divisoria,
    temperatura_ambiente,
    desvio_altura_da_divisoria,
    peca_climatizada,
    desvio_comprimento_face_b,
    fora_de_tolerancia_comprimento_face_oposta,
    desvio_comprimento_face_oposta,
  } = location.state.piece[0] as PiecesData;

  return (
    <div className="h-full flex items-center justify-center">
      <div className="*:p-8 w-full max-w-7xl">
        <h1 className="text-3xl font-bold text-blue-900 text-center">
          {t("adjustsOnProgram")}
        </h1>
        <div className="flex flex-row justify-between gap-12">
          <div className="w-1/2 flex items-center justify-center">
            <img src={resultsPiece} alt="Resultados" />
          </div>
          <div className="w-1/2 flex flex-col my-auto gap-12">
            <p className="text-2xl w-full">
              <b>A1 - </b>
              {t("d1Title").split(" - ")[1]}:{" "}
              <span className="font-bold ml-auto">
                {getDecisionTreeResultD1({
                  d1: desvio_raio_do_esferico,
                  d2: desvio_altura_da_divisoria,
                  temperature: temperatura_ambiente,
                })}{" "}
                mm
              </span>
            </p>
            <p className="text-2xl w-full">
              <b>A2 - </b>
              {t("d2Title").split(" - ")[1]}:{" "}
              <span className="font-bold ml-auto">
                {getDecisionTreeResultD2({
                  climatized: peca_climatizada,
                  ootd2: fora_de_tolerancia_altura_da_divisoria,
                  d2: desvio_altura_da_divisoria,
                })}{" "}
                mm
              </span>
            </p>
            <p className="text-2xl w-full">
              <b>A3 - </b>
              {t("d3Title").split(" - ")[1]}:{" "}
              <span className="font-bold ml-auto">
                {getDecisionTreeResultD3({
                  climatized: peca_climatizada,
                  d3: desvio_comprimento_face_b,
                })}{" "}
                mm
              </span>
            </p>
            <p className="text-2xl w-full">
              <b>A4 - </b>
              {t("d4Title").split(" - ")[1]}:{" "}
              <span className="font-bold ml-auto">
                {getDecisionTreeResultD4({
                  d4: desvio_comprimento_face_oposta,
                  ootd4: fora_de_tolerancia_comprimento_face_oposta,
                })}{" "}
                mm
              </span>
            </p>
          </div>
        </div>
        <button
          className="bg-blue-900 text-white font-bold !px-4 !py-2 rounded-lg mt-8 ml-24 min-w-36 hover:brightness-90 transition-all duration-700"
          onClick={handleBackToDashboard}
        >
          {t("backToDashboard")}
        </button>
      </div>
    </div>
  );
};
